import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import ImagenPeliculas from '../components/imageComponents/imagenPeliculas'

const IndexPage = () => (
  <Layout>
    <SEO title='Películas' />
    <Header siteTitle='PELÍCULAS' />

    <Container>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-5'>
            <ImagenPeliculas />
          </div>
          <div className='col-12 col-md-7 conmargen'>
            <p>
              <b><span className="oro"> OR-PRINTER </span></b> suministra todo tipo de películas:
            </p>
            <ul>
              <li>Mate</li>
              <li>Brillo</li>
              <li>Holográficos</li>
              <li>Toda la gama de colores</li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
