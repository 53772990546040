/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./layout.css"
import Navbar from "./Navbar/Navbar"

const Layout = ({ children }) => {

  return (
    <>

    <Navbar />
      <div>
        <main
         style={{
              paddingBottom: `2.5rem`
        }}
        >{children}</main>
        <footer
         style={{
          padding: `0.5rem 4rem`,
          background:`black`,
          color:`white` ,
          textAlign:`center` ,  
          position: `absolute`,
          bottom: `0`,
          width: '100%',
          height: `2.5rem`
        }}
        >
        	&copy; <span className="oro"> OR-PRINTER </span>
          <Link   className="pie" to="/Contacto/"> &nbsp;- Contacto</Link>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
